import "core-js/modules/es.array.push.js";
import { reactive, toRefs, nextTick, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { sleep } from "@/utils/sleep";
export default {
  name: "developmentPlatform",
  setup() {
    const route = useRoute();
    const router = useRouter();
    const {
      state,
      commit,
      dispatch
    } = useStore();
    const refData = reactive({});
    const sessionId = computed(() => route.query.sessionId || '');
    const userType = computed(() => route.query.userType || '');
    nextTick(async () => {
      //当前路径不存在sesssionId时
      if (!sessionId.value) {
        router.push('/error/404');
      } else {
        commit('setSessionId', sessionId.value);
        localStorage.setItem('FROM_OPEN_PC_H5', 'FROM_OPEN_PC_H5');
        localStorage.setItem('appId', 'appId');
        commit('setDevelopToken', {
          sessionId: sessionId.value,
          expiresIn: 86400
        });
        await sleep(1500);
        router.push('/index');
        // window.location.href = `https://${document.domain}${window.location.port?`:${window.location.port}`:''}`
      }
      ;
    });
    return {
      ...toRefs(refData)
    };
  }
};